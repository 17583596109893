import { CompanyTypeEnum } from '../../constants';

export type ApiCountryT = Api.Definitions.CountryDto;

export type ApiUserT = Api.Definitions.UserInfo;
export type ApiUserUpdateT = Api.Definitions.UpdateUserInfoDto;

export type ApiUserStatusT = Api.Definitions.UserInfo['status'];

export enum UserStatusEnum {
    active = 'ACTIVE',
    pending = 'PENDING',
    archived = 'ARCHIVED',
}

export type ApiCompanyT = Api.Definitions.CompanyDto;
export type ApiPartnerCompanyT = Api.Definitions.BrokerCompanyDto;
export type ApiCompanyChangesT = Api.Definitions.CompanyUpdateDto;
export type ApiLocationT = Api.Definitions.Address;

export type ApiActualOrderRequestT = Api2.Definitions.RfqRequest;

export type ApiAddressPointT = Api2.Definitions.AddressPoint;

export type ApiOrderWaypointT = Api2.Definitions.OrderWaypointDto;

export type ApiRFQT = Api2.Definitions.RFQMultipointFullDto;
export type ApiOrderDetailsT = Api2.Definitions.OrderFullDto;

export type ApiMultiPointPriceOfferT = Api2.Definitions.PriceOfferMultipointDto;
export type ApiContractLaneT = Api2.Definitions.ReservedOrderDto['contractLanes'][number];

export type ApiCreateOrderRequestT = Api2.Definitions.CompleteRFQDto;

export type ApiTransportationOrderTypeT = Api2.Definitions.TransportationOrderType;
export enum TransportationOrderTypeEnum {
    trailer = 'TRAILER',
    truck = 'TRUCK',
    train = 'TRAIN',
}

export type ApiTrailerDictTypeT = Api.Definitions.DictTrailerInfoDto;
export type ApiTrailerTypeT = NonNullable<Api.Definitions.DictTrailerInfoDto['trailerType']>;

export type ApiTrailersDictT = Record<TrailerDictIdT, Array<ApiTrailerDictTypeT>>;

export type ApiEmissionStandartT = NonNullable<Api.Definitions.CarrierTruckDto['emissionClass']>;

// patch query, query parameters "page" and "sort" is missing in backend docs
export type OverwritePageRequestWithSortQueryT<T> = OverwriteT<
    T,
    {
        sort?: string;
    }
>;
export type OverwritePageRequestWithSortT<T> = OverwritePageRequestWithSortQueryT<T> & {
    page: number;
};

export type OverwritePageRequestT<T> = T & {
    page: number;
};

export type FixPageableCommonQueryT = {
    page?: Api2.Parameters.PageNumber;
    size?: Api2.Parameters.PageSize;
};

export enum ShipperOrdersQuickFilterEnum {
    placed = 'placed',
    inProgress = 'in-progress',
    delivered = 'delivered',
    done = 'done',
    withIssues = 'with-issues',
    cancelled = 'cancelled',
}

export type ApiOrderSourceT = Api2.Definitions.OrderSource;
export enum ApiOrdersSourceEnum {
    all = 'ALL',
    imported = 'IMPORTED',
    internal = 'INTERNAL',
}

export enum BrokerOrdersQuickFilterEnum {
    unassigned = 'unassigned',
    runningOutOfTime = 'running-out-of-time',
    ranOutOfTime = 'ran-out-of-time',
    shipmentExpired = 'shipment-expired',
    assigned = 'assigned',
    inTransit = 'in-transit',
    delaying = 'delaying',
    tourCanceled = 'tour-canceled',
    shipmentMissed = 'shipment-missed',
    reloading = 'reloading',
    shipmentCanceled = 'shipment-canceled',
}

export enum DispatchesQuickFilterEnum {
    new = 'new',
    carrierUnassigned = 'carrier-unassigned',
    carrierAssigned = 'carrier-assigned',
    inTransit = 'in-transit',
    canceled = 'canceled',
    delivered = 'delivered',
}

export enum OrderSourceEnum {
    imported = 'imported',
    internal = 'internal',
}
export type OrderSourceT = `${OrderSourceEnum}`;

export type ApiOrderStatusT = Api2.Definitions.OrderStatus;
export enum OrderStatusEnum {
    placed = 'PLACED',
    inProgress = 'IN_PROGRESS',
    canceled = 'CANCELED',
    delivered = 'DELIVERED',
    done = 'DONE',
}

export type ApiDispatchStatusT = Api2.Definitions.DispatchStatus;
export enum DispatchStatusEnum {
    newDispatch = 'NEW_DISPATCH',
    carrierUnassigned = 'CARRIER_UNASSIGNED',
    carrierAssigned = 'CARRIER_ASSIGNED',
    // assetsUnassigned = 'ASSETS_UNASSIGNED',
    // driverUnassigned = 'DRIVER_UNASSIGNED',
    inTransit = 'IN_TRANSIT',
    delivered = 'DELIVERED',
    canceled = 'CANCELED',
    // declined = 'DECLINED',
    // assigned = 'ASSIGNED',
    // done = 'DONE',
    unknown = 'UNKNOWN',
}

export type ApiSpotBidStatusT = SpotApi.Definitions.BidDto['status'];

export enum SpotBidStatusEnum {
    new = 'NEW',
    open = 'OPEN',
    accepted = 'ACCEPTED',
    rejected = 'REJECTED',
    expired = 'EXPIRED',
    won = 'WON',
    lost = 'LOST',
    missed = 'MISSED',
    canceled = 'CANCELED',
}

export enum TransportOrderQuickFilterEnum {
    new = 'new',
    noAssets = 'no-assets',
    noDriver = 'no-driver',

    declined = 'declined',
    assigned = 'assigned',
    inTransit = 'in-transit',
    done = 'done',
    canceled = 'canceled',
}

export type ApiTransportOrderStatusT = Api2.Definitions.TransportationOrderInfoStatus;

export enum TransportOrderStatusEnum {
    assetsUnassigned = 'ASSETS_UNASSIGNED',
    driverUnassigned = 'DRIVER_UNASSIGNED',
    assigned = 'ASSIGNED',
    inTransit = 'IN_TRANSIT',
    declined = 'DECLINED',
    done = 'DONE',
    canceled = 'CANCELED',
    unknown = 'UNKNOWN',
}

export type ApiOrderT = Api2.Definitions.OrderShipperFiltrationResult;

export enum OrderSortEnum {
    createdDate = `createdDate`,
    dropOffDateFrom = `loads.destination.stayFrom`,
    pickupDateFrom = `loads.origin.stayFrom`,
    cost = `dispatches.priceOffer.totalPrice`,
}

export type ApiShipperContractT = Api.Definitions.ApiShipperContractShortDto;
export type ApiShipperContractBrokerT = Api.Definitions.ApiShipperContractBrokerShortDto;
export type ApiShipperContractLaneBrokerT = Api2.Definitions.ShipperContractLaneShortDto;

export type ApiTransportOrderT = Api2.Definitions.TransportationOrderListDto;

export type ApiShipperContractDetailsT = Api.Definitions.ApiShipperContractDto;
export type ApiBrokerShipperContractDetailsT = Api.Definitions.ApiShipperContractBrokerDto;

export type ApiShipperContractLaneDetailsT = Api.Definitions.ShipperContractLaneBrokerDto;
export type ApiBrokerShipperContractLaneDetailsT = Api.Definitions.ShipperContractLaneBrokerDto;

export type ApiTransportOrderStatsT = Api2.Definitions.TransportationOrderStatisticDto;

export type ApiDispatchStatsT = Api2.Definitions.BrokerDispatchStatisticDto;
export type ApiOrdersStatsT = Api2.Definitions.OrderShipperStatistic;

export enum TransportOrderSortEnum {
    dropOffDate = 'destinationDockingHoursFrom',
    pickupDate = 'originDockingHoursFrom',
    receiveDate = 'receiveDate',
    price = 'totalCost',
}

export enum TransportOrderTypeEnum {
    truck = 'TRUCK',
    trailer = 'TRAILER',
    train = 'TRAIN',
}

export enum ApiAdditionalServiceEnum {
    accessorials = 'Accessorials',
    carrier = 'Carrier Certifications',
    box = 'Box Trailer Specification',
    tilt = 'Tilt Trailer Specification',
}

export enum TruckTypesEnum {
    common = 'common',
    regular = 'regular',
    green = 'green',
}

export type ApiDictAdditionalServiceT = Api.Definitions.DictAdditionalServiceDto;

export type ApiSettingNameT = Api.Definitions.SettingDto['name'];
export type ApiSettingT = Api.Definitions.SettingDto;

export type ApiTrailerStatusT = NonNullable<Api.Definitions.CarrierTrailerDto['status']>;

export enum TrailerStatusEnum {
    free = 'FREE',
    inTransit = 'IN_TRANSIT',
    unavailable = 'UNAVAILABLE',
    archived = 'ARCHIVED',
}

export enum TrailersSortEnum {
    added = 'createdDate',
    type = 'dictTrailer.trailerType',
    subtype = 'dictTrailer.model',
    plateNumber = 'plateNumber',
    status = 'status',
}

export type ApiTrailerT = Api.Definitions.TrailerShortDto;

export type ApiTrailerDetailsT = Api.Definitions.BrokerTrailerDto;

export type ApiTrailerAddRequestT = Api.Definitions.CreateTrailerDto;
export type ApiTrailerUpdateRequestT = Api.Definitions.UpdateTrailerDto;

export enum TrucksSortEnum {
    added = 'createdDate',
    model = 'dictTruck.model',
    plateNumber = 'plateNumber',
    status = 'status',
}

export type ApiTruckStatusT = NonNullable<Api.Definitions.BrokerCarrierTruckDto['status']>;

export enum TruckStatusEnum {
    free = 'FREE',
    inTransit = 'IN_TRANSIT',
    unavailable = 'UNAVAILABLE',
    archived = 'ARCHIVED',
}

export type ApiTruckAddRequestT = Api.Definitions.CreateTruckDto;
export type ApiTruckUpdateRequestT = Api.Definitions.UpdateTruckDto;

export type ApiTruckT = Api.Definitions.CarrierTruckShortDto;
export type ApiLinkedTruckT = Pick<ApiTruckT, 'id' | 'plateNumber' | 'dedicated' | 'countryOfRegistration'>;

export type ApiTruckDetailsT = Api.Definitions.BrokerCarrierTruckDto;

export type ApiTruckDictTypeT = Api.Definitions.DictTruckListDto;

export type ApiTrucksDictT = Api.Definitions.DictTruckListDto[];

export enum ContactPositionsEnum {
    operator = 'operator',
    management = 'management',
    sales = 'sales',
    owner = 'owner',
    it = 'it',
}

export type RoleT = NonNullable<Api.Definitions.UserRole['role']>;

export type ApiNewUserRoleT = Api2.Definitions.NewUserRole;

export enum RolesEnum {
    companyAdmin = 'COMPANY_ADMIN',
    serviceUser = 'SERVICE_USER',
    driver = 'DRIVER',
    user = 'USER',
    keyAccountManager = 'KEY_ACCOUNT_MANAGER',
    dispatcher = 'DISPATCHER',
}

export type AuthUserClaimRoleT = CompanyTypeEnum | RolesEnum;

export type ApiAddCompanyUserRequestT = Api2.Definitions.InviteUserDto;

export type ApiCompanyContactRequestT = Api.Definitions.ContactDto;
export type ApiCompanyContactMediaT = Api.Definitions.ContactMediaDto;

export type ApiCompanyContactT = Api.Definitions.ContactShortDto;

export type ApiCompanyContactRoleT = Api.Definitions.UserRole;
export type ApiCompanyContactDetailsT = Api.Definitions.ContactDto;

export type ApiCompanyDriverT = Api2.Definitions.DriverListDto;

export type ApiCompanyDriverDetailsT = Api2.Definitions.DriverDto;

export type ApiInviteDriverRequestT = { phone: string };
export type ApiUpdateDriverRequestT = Api2.Definitions.DriverUpdateDto;

export enum DriversSortEnum {
    name = 'contact_name',
    phone = 'contact_mobilePhone',
    email = 'email',
    status = 'status',
}

export enum DriverStatusEnum {
    pending = 'PENDING',
    free = 'FREE',
    inTransit = 'IN_TRANSIT',
    archived = 'ARCHIVED',
}

export type PartnerTypeT = Api2.Definitions.CompanyType;
export enum PartnerTypeEnum {
    shipper = 'SHIPPER',
    carrier = 'CARRIER',
    broker = 'BROKER',
}

export type ApiPartnerT = Api.Definitions.CompanyShortDto;

export enum PartnersSortEnum {
    name = 'name',
}

export type ApiCarrierContractStatusT = Api2.Definitions.CarrierContractStatus;

export enum CarrierContractStatusEnum {
    waitingForApprove = 'WAITING_FOR_APPROVE',
    approved = 'APPROVED',
    rejected = 'REJECTED',
    revoked = 'REVOKED',
    expired = 'EXPIRED',
}

export type ApiDocumentStatusT = NonNullable<Api.Definitions.DocumentShortDto['status']>;

export enum DocumentStatusEnum {
    waitingForApprove = 'WAITING_FOR_APPROVE',
    approved = 'APPROVED',
    rejected = 'REJECTED',
    archived = 'ARCHIVED',
    revoked = 'REVOKED',
    expired = 'EXPIRED',
}

export type ApiInvoiceStatusT = NonNullable<Api.Definitions.BrokerInvoiceDto['status']>;

export enum InvoiceStatusEnum {
    draft = 'DRAFT',
    open = 'OPEN',
    void = 'VOID',
    paid = 'PAID',
    uncollectible = 'UNCOLLECTIBLE',
}

export type ApiDocumentTypeT = Api.Definitions.DocumentShortDto['documentType'];

export enum DocumentTypeEnum {
    certificateOfIncorportion = 'CERTIFICATE_OF_INCORPORATION',
    evidenceOfTaxVat = 'EVIDENCE_OF_TAX_VAT',
    transportationAgreement = 'TRANSPORTATION_AGREEMENT',
    termsAndConditions = 'TERMS_AND_CONDITIONS',
    creditScoreCheck = 'CREDIT_SCORE_CHECK',
    GDP = 'GDP',
    IFS = 'IFS',
    SQAS = 'SQAS',
    wasteManagement = 'WASTE_MANAGEMENT',
    ISO_9001 = 'ISO_9001',
    ISO_14001 = 'ISO_14001',
    goodsVehicleOperatorLicenseForNationalTransportation = 'GOODS_VEHICLE_OPERATOR_LICENSE_FOR_NATIONAL_TRANSPORTATION',
    goodsVehicleOperatorLicenseForInternationalTransportation = 'GOODS_VEHICLE_OPERATOR_LICENSE_FOR_INTERNATIONAL_TRANSPORTATION',
    cargoInsurancePolicy = 'CARGO_INSURANCE_POLICY',
}

export enum DocumentRequiredEnum {
    must = 'MUST',
    optional = 'OPTIONAL',
    notApplicable = 'NOT_APPLICABLE',
}

export type ApiDictDocumentT = Api.Definitions.DictDocumentDto;

export type ApiDocumentT = Api.Definitions.DocumentShortDto;

export type ApiContactFormalT = NonNullable<Api.Definitions.ContactDto['formal']>;

export enum ApiContactFormalEnum {
    mr = 'MR',
    mrs = 'MRS',
    dr = 'DR',
    prof = 'PROF',
    mag = 'MAG',
}

export type ApiDictLegalFormT = Api.Definitions.DictLegalFormDto;

export type ApiPartnerDetailsT = Api.Definitions.BrokerCompanyDto;
export type CountryCodeT = Api.Definitions.CountryDto['code'];
export type ApiPartnerContractT = Api2.Definitions.CarrierContractShortDto;
export type ApiPartnerContractDetailsT = Api2.Definitions.CarrierContractDto;

export type ApiRevokePartnerContractInfoT = Api2.Definitions.RevokeCarrierContractRequest;

export enum RevokeContractActionEnum {
    setNewContract = 'SET_NEW_CONTRACT',
    keepOldRate = 'KEEP_OLD_RATE',
    setNewRate = 'SET_NEW_RATE',
}

export enum AddressTypeEnum {
    legal = 'legal',
    postal = 'postal',
    billing = 'billing',
}

export type ApiEmployeeT = Api.Definitions.UserShortDto;

export type ApiRateT = Api.Definitions.ManualRateDto;

export type ApiRatesT = Array<ApiRateT>;

export enum UpdateRateStrategyEnum {
    exactValue = 'EXACT_VALUE',
    diff = 'PLUS',
    percent = 'PLUS_PERCENTS',
}

export enum RulesSortEnum {
    name = 'name',
}

export type ApiRuleDraftT = Omit<Api.Definitions.ManualRateRuleDto, 'active' | 'id'>;

export type ApiRuleDetailsT = Api.Definitions.ManualRateRuleDto;

export type ApiRuleT = Api.Definitions.ManualRateRuleDto;
export type ApiRulesT = Array<ApiRuleT>;

export type ApiSettingsKeysT = Api.Definitions.SettingDto['name'];
export type ApiSystemSettingsT = Partial<Record<ApiSettingsKeysT, string>>;

export enum DispatchesSortEnum {
    pickupDate = `pickUpDateFrom`,
    dropOffDate = `dropOffDateFrom`,
    createdDate = `createdDate`,
}

export enum BrokerOrderSortEnum {
    pickupDate = `loads.pickupDockingHoursFrom`,
    dropOffDate = `loads.dropOffDockingHoursTo`,
    createdDate = `createdDate`,
}

export type ApiDispatchT = Api2.Definitions.DispatchListDto;

export type ApiBrokerOrderT = Api.Definitions.BrokerOrderShort;
export type ApiBrokerOrderDetailsT = Api.Definitions.OrderDto;

export type ApiPartnerCreationRequestT = ApiPaths.ApiV1BrokerCompany.Post.RequestBody;
export type ApiInvitePartnerRequestT = {
    email: string;
    partnerType: PartnerTypeEnum;
};

export type ApiAssignmentVehicleT = Api2.Definitions.AssetWithAvailableHooks;

export type ApiCarrierUtilizationT = Api.Definitions.CarrierUtilization;

export type ApiTransportationOrderFullT = Api2.Definitions.TransportationOrderFullDto;
export type ApiShipmentDetailsT = Api2.Definitions.ShipmentFullInfo;
export type ApiDispatchWaypointDetailsT = Api2.Definitions.DispatchWaypointDto;

export type ApiDispatchWaypointT = Api2.Definitions.DispatchWaypointDto;
export type ApiDispatchDetailsT = Api2.Definitions.DispatchFullDto;
export type ApiTransportationOrderWaypointT = Api2.Definitions.TransportationOrderWaypointDto;
export type ApiCarrierTransportOrderDetailsT = Api2.Definitions.CarrierTransportationOrderFullDto;
export type ApiBrokerTransportOrderDetailsT = Api2.Definitions.BrokerTransportationOrderFullDto;
export type ApiTransportOrderDetailsT =
    | Api2.Definitions.BrokerTransportationOrderFullDto
    | Api2.Definitions.CarrierTransportationOrderFullDto;

export type ApiStopTypeT = Api2.Definitions.StopType;
export enum StopEnum {
    pickupTrailer = 'PICKUP_TRAILER',
    pickupTruck = 'PICKUP_TRUCK',
    pickupRoadTrain = 'PICKUP_ROAD_TRAIN',
    pickupDeliveryShipment = 'PICKUP_DELIVERY_SHIPMENT',
    driveThrough = 'DRIVE_THROUGH',
    dropAndHook = 'DROP_AND_HOOK',
    dropTrailer = 'DROP_TRAILER',
    dropTruck = 'DROP_TRUCK',
    dropRoadTrain = 'DROP_ROAD_TRAIN',
}

export type ApiUnitTypeT = NonNullable<Api.Definitions.FullShipmentDto['unitsType']>;

export type ApiTourStatusT = Api2.Definitions.TourCalculatedStatus;
export enum TourStatusEnum {
    inTransit = 'IN_TRANSIT',
    finished = 'FINISHED',
    canceled = 'CANCELED',
    carrierUnassigned = 'CARRIER_UNASSIGNED',
    carrierAssigned = 'CARRIER_ASSIGNED',
}

export type ApiShipmentStatusT = Api2.Definitions.ShipmentStatus;
export enum ShipmentStatusEnum {
    awaitingLoading = 'AWAITING_LOADING',
    loading = 'LOADING',
    inTransit = 'IN_TRANSIT',
    unloading = 'UNLOADING',
    delivered = 'DELIVERED',
    canceled = 'CANCELED',
}

export type ApiDriverStatusT = Api2.Definitions.DriverListDto['status'];

export type ApiShortDriverT = Api2.Definitions.DriverListDto;

export type ApiNotificationT = Api2.Definitions.NotificationDto & {
    data: any; // see descriptor
};

export type ApiNotificationSubjectTypeT = NonNullable<Api2.Definitions.NotificationDto['subjectType']>;

export enum SubjectTypeEnum {
    order = 'ORDER',
    tour = 'TOUR',
    shipment = 'SHIPMENT',
    transportationOrder = 'TRANSPORTATION_ORDER',
    dispatch = 'DISPATCH',
    rfq = 'RFQ',
    document = 'DOCUMENT',
    carrierContract = 'CARRIER_CONTRACT',
    shipperContract = 'SHIPPER_CONTRACT',
    shipperContractLane = 'SHIPPER_CONTRACT_LANE',
}

export type RuleImpactStrategyT = NonNullable<Api.Definitions.ManualRateRuleDto['targetPriceValueType']>;

export type ApiUpdateDockingHoursQueryT = Array<Api2.Definitions.StopWindowDto>;

export type ApiGroupedNotificationCountsT = NonNullable<
    Api2Paths.ApiV1NotificationsUnreadCountByActionByStatus.Get.Responses.$200['byAction']
>;
export type GroupedNotificationCountsT = {
    DISPATCH_STATUS_CHANGED?: {
        CANCELED?: number;
        DELIVERED?: number;
    };
    TRANSPORTATION_ORDER_STATUS_CHANGED?: {
        DECLINED?: number;
        CANCELED?: number;
        DONE?: number;
    };
    ORDER_STATUS_CHANGED?: {
        DONE?: number;
        CANCELED?: number;
    };
};

export enum TollTypeEnum {
    general = 'GENERAL',
    marketCorrection = 'MARKET_CORRECTION',
    intermodal = 'INTERMODAL',
    city = 'CITY',
    bridge = 'BRIDGE',
    tunnel = 'TUNNEL',
    ferry = 'FERRY',
    mountainPass = 'MOUNTAIN_PASS',
    unspecified = 'UNSPECIFIED',
}
export const ALL_TOLL_TYPES = [
    TollTypeEnum.general,
    TollTypeEnum.marketCorrection,
    TollTypeEnum.intermodal,
    TollTypeEnum.mountainPass,
    TollTypeEnum.city,
    TollTypeEnum.bridge,
    TollTypeEnum.tunnel,
    TollTypeEnum.ferry,
    TollTypeEnum.unspecified,
] as const;

export type ApiTollByRoadTypeT = Api2.Definitions.TollByRoadType;

export type SetUnavailableVehicleQueryT = Api.Definitions.ApiCreateVehicleSchedule;
export type ApiScheduleAddressT = Api.Definitions.ApiScheduleAddressDto;

export enum VehicleScheduleEventTypeEnum {
    shipment = 'SHIPMENT',
    serviceMaintenance = 'SERVICE_MAINTENANCE',
}

export enum RFQSourceEnum {
    imported = 'imported',
    internal = 'internal',
}

export type ApiRFQStatusT = Api2.Definitions.RFQStatus;
export enum RFQStatusEnum {
    open = 'OPEN',
    expireSoon = 'EXPIRE_SOON',
    expired = 'EXPIRED',
    completed = 'COMPLETED',
    closed = 'CLOSED',
}

export enum RFQsSortEnum {
    creationDate = 'createdDate',
    deadline = 'expiration',
}

export enum RFQsQuickFilterEnum {
    open = 'open',
    preExpire = 'expireSoon',
    expired = 'expired',
    completed = 'completed',
    closed = 'closed',
}

export type ApiRFQsStatsT = Api2Paths.ApiV2RfqsCounts.Get.Responses.$200;

// TODO remove
/**
 *  @deprecated
 *  */
export type CountryCodeUnionT = NonNullable<Api.Definitions.DictLegalFormDto['countryCode']>;

export type ApiSpotCarrierProfileT = SpotApi.Definitions.CarrierProfileDto;

export type ApiCountriesT = Api2.Definitions.Countries;

export enum ShipperContractsSortEnum {
    addedDate = 'createdDate',
    validTillDate = 'validTill',
}

export enum ShipperContractLanesSortEnum {
    addedDate = 'createdDate',
    validTillDate = 'validTill',
}

export type ApiCompleteRegistrationT = Api2.Definitions.CompleteRegistrationDto;

export type ApiRegistrationCompanyRequestDraftT = Omit<
    Api2.Definitions.RegistrationCompanyRequest,
    'language' | 'companyType'
>;

export type ApiShipperContractStatusT = Api.Definitions.ApiShipperContractDto['status'];

export enum ShipperContractStatusEnum {
    active = 'ACTIVE',
    revoked = 'REVOKED',
    expired = 'EXPIRED',
}

export type ApiShipperContractLaneStatusT = Api2.Definitions.ShipperContractLaneStatus;

export enum ShipperContractLaneStatusEnum {
    active = 'ACTIVE',
    completed = 'COMPLETED',
    revoked = 'REVOKED',
    expired = 'EXPIRED',
}

export type ApiOrderWaypointTypeT = Api2.Definitions.OrderWaypointDto['type'];

export enum OrderWaypointTypeEnum {
    pickupDeliveryWaypoint = 'PICKUP_DELIVERY_WAYPOINT',
    driveThroughWaypoint = 'DRIVE_THROUGH_WAYPOINT',
    dropAndHook = 'DROP_AND_HOOK',
}

export type ApiShipmentWarningT = NonNullable<Api.Definitions.FullShipmentDto['warning']>;
export enum ShipmentWarningEnum {
    noShow = 'NO_SHOW',
    missed = 'MISSED',
}
